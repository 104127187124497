(function($) {
  $( document ).ready(function() {
    if ($(window).width() < 480) {
      var anchorOffset = 0
    } else {
      var anchorOffset = 70
    }

    $('a[href*="#"]:not([href="#"])').click(function(e) {
      var offset = 0;
      var speed = 1000;
      var target = $(this.hash);

      e.preventDefault()

      $("html,body").animate({
          scrollTop: target.offset().top - anchorOffset
      }, speed);
    });

    $(".externalLink, .externalLink a").attr("target","_blank");
  })
})(jQuery);
