(function($) {
  $( document ).ready(function() {
    $('.cardLink-link').hover(function() {
      $(this).parent().toggleClass('cardLink--hover');
    }, function() {
      $(this).parent().toggleClass('cardLink--hover');
    });

    lightbox.option({
      'alwaysShowNavOnTouchDevices': true,
      'wrapAround': true
    })
  })
})(jQuery);
